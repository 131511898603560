
import {
  NbComponentStatus,
} from '@nebular/theme';
import { Observable } from 'rxjs';

export abstract class AppUtils {
	
	abstract showToast(type: NbComponentStatus, title: string, body: string, resp: any): void;
	abstract getTimestamp(): string;
  
  abstract setSpinnerActive(data:boolean);
  abstract getSpinnerActive(): Observable<any>;
}