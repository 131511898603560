import { Component } from '@angular/core';
import { AppUtils } from '../../../@core/interface/utils';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column [nbSpinner]="loading" nbSpinnerStatus="success" nbSpinnerSize="giant">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  pendingRequestCount = 0;
  loading = false;
  constructor(
    private utilService : AppUtils
  ) {
    utilService.getSpinnerActive().subscribe(resp => {
      if(resp){
        this.pendingRequestCount++;
      }else{
        this.pendingRequestCount--; 
      }

      if(this.pendingRequestCount > 0){
        this.loading = true;
      }else{
        this.loading = false;
      }
    });
  }
}
